import {StyleSheet} from 'react-native';

export default StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: "column"
    },
    imageBackground: {
        flex: 1,
        resizeMode: "cover",
        justifyContent: "center",
        paddingBottom: 15,
        alignItems: 'center',
    },
    text: {
        fontSize: 15,
        textAlign: "justify",
        fontFamily: 'OpenSans',
    },
    titolContainer: {
        backgroundColor: "#a88f40",
        paddingTop: 15,
        paddingBottom: 15,
        width: "70%",
        textAlign: "center",
        alignSelf: 'center',
    },
    descriptionContainer: {
        width: "70%",
        textAlign: "center",
        alignSelf: 'center',
    },
    titol: {
        fontSize: 30,
        textAlign: "center",
        fontWeight: "bold",
        width: '100%',
        alignSelf: 'center',
        color: "white"
    },
    subtitol: {
        fontSize: 20,
        textAlign: "center",
        fontWeight: "500",
        width: '100%',
        alignSelf: 'center',
        color: "white"
    },
    descripcio: {
        marginTop: 14,
        color: '#715f4d',
        fontSize: 20,
        textAlign: "justify",
        lineHeight: 26
    },
    questionBackground: {
        width: "80%",
        height: "80%",
        justifyContent: "center",
        backgroundColor: "#F8F4D3",
        borderRadius: 35
    },
    question: {
        color: '#715f4d',
        fontSize: 15,
        fontWeight: "bold",
        textAlign: "center",
        paddingLeft: "15%",
        paddingRight: "15%"
    },
    TouchableOpacity: {
        height: "100%",
        width: "100%",
        position: "absolute"
    },
    containerAnswer: {
        backgroundColor: '#a88f40',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        height: "100%",
        width: "100%",
    },
    containerAnswerBack: {
        backgroundColor: '#F8F4D3',
        justifyContent: 'center',
        alignItems: 'center',
        height: "100%",
        width: "100%",
    },
    answer: {
        color: "white",
        fontWeight: "bold",
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        fontSize: 20
    },
    answerBack: {
        color: "#a88f40",
        fontWeight: "bold",
        fontSize: 15
    },
    talpSolucio: {
        height: 70,
        width: 70
    },
    questionImage: {
        height: "90%",
        width: "90%"
    },
    containerContinue: {
        marginTop: 20,
        paddingLeft: 15,
        paddingRight: 15,
        height: 50,
        backgroundColor: '#403d27',
        justifyContent: 'center',
        alignItems: 'center',

    },
    continue: {
        color: "white",
        fontWeight: "bold",
        fontSize: 20
    },
    safeAreaAnswer: {
        width: "70%",
        justifyContent: 'center',
        alignItems: 'center',
    },
    cardContainer: {
        height: 150,
        width: 150,
        margin: 4,
        justifyContent: 'center',
        alignItems: 'center',
    },
    answerInput : {
        width: "100%",
        borderColor: "#a88f40",
        borderWidth: 10,
        borderStyle: "solid",
        backgroundColor: "white",
        padding: 10,
        color: "#a88f40",
        fontWeight: 500,
        fontSize: 19
    },
    popUpTitle: {
        color: "#715f4d",
        fontSize: 28,
        textAlign: "center",
        fontWeight: "bold",
        lineHeight: 28,
        paddingBottom: 28
    },
    popUpSubtitle: {
        color: "#252525",
        fontSize: 20,
        textAlign: "center",
        fontWeight: "600",

    },
    popUpIndicatorText: {
        color: "#252525",
        fontSize: 23,
        fontWeight: "bold",
        textAlign: "center",
        width: "83%"
    },
    popUpButton: {
        marginTop: 20,
        paddingLeft: 15,
        paddingRight: 15,
        height: 50,
        backgroundColor: "#a88f40",
        justifyContent:'center'
    },
    popUpButtonText: {
        color: "white",
        fontSize: 23,
        fontWeight: "bold"
    },
    modalOutBackground: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0,0,0,0.5)'
    },
    caixa: {
        display: "flex",
        height: "50%",
        width: "70%",
        borderColor: "#a88f40",
        borderStyle: "solid",
        borderWidth: 6
    },
    crossBox: {
        position: 'absolute',
        right: 6,
        top: 6
    },
    cross: {
        color: "white",
        fontSize: 20
    },
    crossImage: {
        height: 30,
        width: 30
    },
});