//var api_url = 'http://iternaturastrapi.ferranmaso.com';
//var api_url = 'https://iternaturastrapi.cenobify.com'
//var token = 'hejoi32nyrt8rn32ued8fnsy78nr3ui2o1lkdkkajfidsa';
var api_url = "https://strapi.iternatura.es";
var api_cenobify = "127.0.0.1" //"https://apijoc.geoparc.cat"
//var api_url = "https://catcar-wa.iec.cat/"
var token = 'hejoi32nyrt8rn32ued8fnsy78nr3ui2o1lkdkkajfidsa';
var tokenVestigis = 'mMtWqQ0lEAIBLsvQqXm7AK9rrQ165gtf';
var tokenCamins = 's393KoRnR2PzUNySHed4GwlmVZQetxvs';
var tokenClio = 'NRA1tGCl8ZHetrLONKory8L7e4kB7i0I';
var tokenCharlie = 'eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ';
var tokenGarraf = '1nBk4JmhPEkMMEMKNQCC5gCS5f2aOQfZ'
export default {
    api_url: api_url,
    api_cenobify: api_cenobify,
    postEstadistiques: api_cenobify + '/datos/new',
    postSatisfaccio: api_cenobify + '/satisfaccion/new',
    projecte: api_url + '/projectes?token=' + token,
    vestigis: api_url + '/projectes?token=' + tokenVestigis,
    camins: api_url + '/projectes?token=' + tokenCamins,
    premis: api_url + '/premis',
    clio: api_url + '/projectes?token=' + tokenClio,
    geoparc: api_url + '/projectes?token=' + tokenCharlie,
    geoparc_poi: api_url + '/itineraris?id=',
    geoparc_poi_all: api_url + '/itineraris',
    geoparc_premis: api_url + '/premis?projecte=',

    garraf: api_url + '/projectes?token=' + tokenCharlie,
    garraf_poi: api_url + '/itineraris?id=',
    garraf_poi_all: api_url + '/itineraris',
    garraf_premis: api_url + '/premis?projecte=',
};