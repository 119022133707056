import {
    StyleSheet,Dimensions
} from 'react-native';

export default StyleSheet.create({
    modalOutBackground: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0,0,0,0.5)'
    },
    caixa: {
        display: "flex",
        height: "80%",
        width: "80%",
        borderColor: "#a88f40",
        borderStyle: "solid",
        borderWidth: 6,
        borderRadius:5
    },
    caixaInicial: {
        display: "flex",
        height: "80%",
        width: "70%",
        borderColor: "#a88f40",
        borderStyle: "solid",
        borderWidth: 6,
        borderRadius:5
    },
    caixaFinal: {
        display: "flex",
        height: "80%",
        width: "70%",
        borderColor: "#a88f40",
        borderStyle: "solid",
        borderWidth: 6,
        borderRadius:5
    },
    crossBox: {
        position: 'absolute',
        right: 6,
        top: 6
    },
    cross: {
        color: "white",
        fontSize: 20
    },
    crossImage: {
        height: 40,
        width: 40
    },
    popUpTitle: {
        color: "#02488e",
        fontSize: 28,
        textAlign: "center",
        fontWeight: "bold",
        lineHeight: 28,
        paddingBottom: 18,
        marginTop: "20%" , 
    },
    popUpTitleInicial: {
        color: "white",
        fontSize: 28,
        textAlign: "center",
        fontWeight: "bold",
        lineHeight: 28,
        paddingBottom: 14
    },
    popUpSubtitle: {
        color: "white",
        fontSize: 18,
        textAlign: "center",
        fontWeight: "600",
    },
    popUpSubtitleConfeti: {
        color: "#252525",
        fontSize: 20,
        textAlign: "center",
        fontWeight: "bolder",

    },
    popUpIndicatorText: {
        color: "white",
        fontSize: 23,
        fontWeight: "bold",
        textAlign: "center"
    },
    popUpButton: {
        marginTop: 20,
        paddingLeft: 15,
        paddingRight: 15,
        height: 50,
        backgroundColor: "#3f3b22",
        justifyContent:'center',
        borderRadius: 5,
    },
    popUpButtonText: {
        color: "white",
        fontSize: 23,
        fontWeight: "bold"
    },
    scroll : {justifyContent:'center',flexGrow:1},    
    imageBackground: {
        flex: 1,
        resizeMode: "cover",
        justifyContent: "center",
        paddingBottom: 15,
        alignItems: 'center',
    },
});