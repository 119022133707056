import {StatusBar} from 'expo-status-bar'
import React, {Component} from 'react'
import {Text, ImageBackground, View, Image, Alert, TouchableOpacity, Platform, Dimensions} from 'react-native'
import api from './components/config/api_urls'
import * as Font from 'expo-font'
import * as RNLocalize from "react-native-localize"
import ReactMarkdown from 'react-markdown'
import * as WebBrowser from 'expo-web-browser'
import {setAnalyticsCollectionEnabled} from 'expo-firebase-analytics'
//Navegacio
import {createStackNavigator} from '@react-navigation/stack'
import {NavigationContainer} from '@react-navigation/native'
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs'
//import Proves from './components/proves/proves'
import Home from './components/home/home'
import Home2 from './components/home2/home2'
import Prova from './components/proves/proves'
import Inici from './components/inici/inici'
import VideoPrincipal from './components/videoPrincipal/videoPrincipal'
import Telefon from './components/telefon/telefon'
import Login from './components/login/login'
import Perfil from './components/perfil/perfil'
import Mineral from './components/perfil/mineral/mineral'
import Joc1 from './components/joc1/joc1'
import Joc2 from './components/joc2/joc2'
import Joc3 from './components/joc3/joc3'
import Joc3Completa from './components/joc3/joc3PantallaComplerta'
import Credits from './components/credits/credits'
import Rondalla from './components/laRondalla/rondalla'
import Pagina6 from './components/laRondalla/pagina6'
import AsyncStorage from '@react-native-community/async-storage'
import VideoPantallaComplerta from './components/video/video'
import MenuPosicionament from './components/menuClio/menuClio'
import Mapa from './components/Mapa/mapa'
import QuizzText from './components/jocsMapa/quizzText/quizzText'
import Itineraris from './components/itineraris/itineraris'
import InfoItinerari from './components/infoItinerari/infoItinerari'
import Premi from './components/jocsMapa/premi/premi'
//Style caixeti
import style from './components/credits/style'

import * as ScreenOrientation from 'expo-screen-orientation'
import JocTest from './components/jocsMapa/jocTest/jocTest'
import Quizz from './components/jocsMapa/quizz/quizz'
import CheckIn from './components/jocsMapa/checkIn/checkIn'
import DragAndDrop from "./components/jocsMapa/dragAndDrop/dragAndDrop";
import {createToken} from "./helpers/functions";
import {postStadistic} from "./helpers/petitions";

//ordre 0
class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {loadedData: false, fontLoaded: false};
        this.changed = false;
        this.state['veDePerfil'] = true
        this.state['pop_up_height'] = Dimensions.get('screen').height * (13 / 15) * 0.67;
        this.state['pop_up_margin_top'] = Dimensions.get('screen').height * (13 / 15) * 0.22;
        this.state['width'] = Dimensions.get('window').width;
        this.state['height'] = Dimensions.get('window').height;
        this.state['navigation'] = this.props.navigation;

    }

    // changeScreenOrientation = async () => {
    //     await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT);
    // }

    componentDidMount() {
        // this.changeScreenOrientation();
        setAnalyticsCollectionEnabled(true)
        let locales = RNLocalize.getLocales();
        if (Array.isArray(locales)) {
            //Agafa el primer idioma del telefon
            locales = locales[0].languageTag;
        }
        // console.log(locales);
        console.log("Idioma: " +locales.substring(0, 2));
        switch (locales.substring(0, 2)) {
            // case 'es':
            //     AsyncStorage.setItem('idioma','_ES');
            //     this.setState({idioma: '_ES'});
            //     break;
            // case 'en':
            //     AsyncStorage.setItem('idioma','_EN');
            //     this.setState({idioma: '_EN'});
            //     break;
            // case 'ca':
            //     AsyncStorage.setItem('idioma', '');
            //     this.setState({idioma: ''});
            //     break;
            // case 'fr':
            //     AsyncStorage.setItem('idioma', '_FR');
            //     this.setState({idioma: '_FR'});
            //     break;
            default:
                AsyncStorage.setItem('idioma', '');
                this.setState({idioma: ''});
                break;
        }
        Font.loadAsync({
            //IMPORTANT: Fonts a utilitzar
            'Lobster': require('./assets/fonts/Carolingia.otf'), // CLIO
            // 'Lobster': require('./assets/fonts/Formata.otf'), // Vestigis
            //'Lobster': require('./assets/fonts/Lobster.ttf'), // Met De Ribes
            'OpenSans': require('./assets/fonts/Open_Sans/OpenSans-Regular.ttf')
        });

        this.getData()
        this.getMostarCredits()
    }

    getLastPoiTrucada = async () => {
        try {
            const value = await AsyncStorage.getItem('lastPoiTrucada');
            //  console.log(value);
            if (value !== null) {
                this.state.premiTrucada = JSON.parse(value);
                this.props.navigation.navigate('Telefon', this.state);
            } else {
            }
        } catch (e) {
            console.log(e);
        }
    }

    getMostarCredits = async () => {
        try {
            const value = await AsyncStorage.getItem('anarCredits');
            //  console.log(value);
            if (value !== null) {
                if (value == "false" || value == false) {
                    this.setState({veDePerfil: false})
                }
            }
        } catch (e) {
            console.log(e);

        }
    }

    getData() {
        return fetch(api.garraf, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        })
            .then(response => response.json())
            .then( async responseJson => {
                // console.log(responseJson)
                await this.setState(
                    {
                        itemId: 856,
                        projecte: responseJson[0],
                        recursos: responseJson[0].recursos,
                        premisAlternatius: responseJson[0].premi_alternatiuses,
                        urlImatgeInicial: (responseJson[0]['ImatgeInicial' + this.state.idioma]) ? api.api_url + responseJson[0]['ImatgeInicial' + this.state.idioma].url : "",
                    }
                );
                await this.getLastPoiTrucada()
                await this.getDataPois()

                this.setState({loadedData: true});

                /*if (this.state.urlImatgeInicial == "") this.changeScreen();*/

            })
            .catch(error => {
                console.error(error);
            });
    }

    getDataPois() {
        return fetch(api.geoparc_poi_all, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        })
            .then(response => response.json())
            .then(responseJson => {
                this.setState(
                    {
                        itineraris: responseJson,
                    }
                );

                /*if (this.state.urlImatgeInicial == "") this.changeScreen();*/

            })
            .catch(error => {
                console.error(error);
            });
    }

    async changeScreen() {
        // console.log("changing screen")
        if (!this.changed) {
            const nom_login = await AsyncStorage.getItem('nom');
            let token = await AsyncStorage.getItem('user_token');
            if(!token) {
                token = createToken(35)
                postStadistic({token: token, tipus_peticio: "Inici", peticio: "Inici joc"})
                await AsyncStorage.setItem('user_token', token);
            }
            this.state["user_token"] = token
            if(nom_login) {
                // this.props.navigation.navigate('Home', this.state);
                let itinerari = this.state.itineraris.find(e => e.Nom == "A la recerca d'en Charlie"); //=> Canviar en cas de canvi de nom
                this.loadItinerari(itinerari)
            }
            else {
                // this.props.navigation.navigate('VideoPrincipal', this.state)
                this.props.navigation.navigate('Login', this.state)
            }

            this.changed = true;
        }
    }

    
loadItinerari(itinerari) {

    this.state.itinerariActual = itinerari;
    
    let retrievedObject = localStorage.getItem('itinerari' + this.state.itinerariActual.id);
    if (retrievedObject) {
        this.state['currentStateItinerari'] = JSON.parse(retrievedObject);
    }
    this.props.navigation.navigate('Mapa', this.state)
}

    


    mostrarPaginaPrincipal() {
        if (this.state.projecte.MostrarPantallaLogos && this.state.veDePerfil) {
            if (this.state.projecte.MostrarTextExplicatiu) {
                return (
                    <View style={{
                        marginTop: this.state.pop_up_margin_top * 0.5,
                        height: (this.state.pop_up_height * 1.5)
                    }}>
                        <View style={{flex: 1, alignContent: 'center', alignSelf: 'center'}}>
                            <Image
                                source={this.state.projecte.ImatgePrincipalTextExplicatiu ? {uri: api.api_url + this.state.projecte.ImatgePrincipalTextExplicatiu.url} : null}
                                style={{
                                    height: this.state.pop_up_height * 0.6,
                                    width: this.state.pop_up_height * 0.6,
                                    resizeMode: 'contain',
                                    alignSelf: 'center'
                            }}/>
                            <View style={{flexDirection: 'row', alignSelf: 'center', paddingTop: 15}}>
                                <Text style={{
                                    fontFamily: 'Lobster',
                                    fontSize: 21,
                                    textAlign: "center",
                                    color: "#006fb7",
                                    fontSize: 25,
                                    paddingBottom: 20
                                }}>
                                    {this.state.projecte['TextExplicatiu' + this.state.idioma]}
                                </Text>
                                <TouchableOpacity onPress={() => {
                                    this._handlePressButtonAsync(this.state.projecte.UrlTextExplicatiu ? this.state.projecte.UrlTextExplicatiu : null)
                                }}>
                                    <Image
                                        source={this.state.projecte.ImatgeBotoTextExplicatiu ? {uri: api.api_url + this.state.projecte.ImatgeBotoTextExplicatiu.url} : null}
                                        style={{
                                            height: this.state.pop_up_height * 0.2,
                                            width: this.state.pop_up_height * 0.2,
                                            resizeMode: 'contain',
                                            alignSelf: 'center'
                                        }}/>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                )
            } else {
                if (this.state.projecte.MostrarCaixeti) {
                    return (
                        <View style={{
                            marginTop: this.state.pop_up_margin_top * 0.5,
                            height: (this.state.pop_up_height * 1.5)
                        }}>
                            <View style={{flex: 1,}}>
                                <View style={[style.caixaOut, {backgroundColor: this.state.projecte.colorPrincipal2}]}>
                                    <View style={[style.caixa, this.state.projecte.BorderContenidor ? style.caixaBorderContenidor : null, {borderColor: this.state.projecte.ColorBorderContenidor}]}>
                                        <Text style={{
                                            fontFamily: 'Lobster',
                                            fontSize: 21,
                                            textAlign: "center",
                                            paddingTop: 5,
                                            paddingBottom: 10,
                                            color: "white",
                                            fontSize: 28
                                        }}>
                                            {this.state.projecte['TitolPantallaLogos' + this.state.idioma]}
                                        </Text>
                                        <Image style={{height: this.state.pop_up_height * 0.8, resizeMode: 'contain'}}
                                               source={{uri: this.state.urlImatgeInicial}}/>
                                    </View>
                                </View>
                            </View>
                        </View>
                    )
                } else {
                    return (
                        <View style={{
                            marginTop: this.state.pop_up_margin_top * 0.5,
                            height: (this.state.pop_up_height * 1.5)
                        }}>
                            <View style={{alignContent: 'center'}}>
                                <Image style={{height: this.state.pop_up_height * 0.95, resizeMode: 'contain'}}
                                       source={{uri: this.state.urlImatgeInicial}}/>
                            </View>
                            </View>
                    )
                }
            }
        } else {
            this.changeScreen();
        }
    }



    _handlePressButtonAsync = async (link) => {
        let result = await WebBrowser.openBrowserAsync(link);
    };

    render() {
        if (this.state.loadedData) {
            const imageHeight = Dimensions.get('screen').height * (13 / 15) * 0.20
            // const imageWidth = imageHeight * 250 / 250
            const imageWidth = Dimensions.get('screen').width * 250/250
            // const imageHeight = Dimensions.get('screen').height * (13 / 15) * 0.20
            // const imageWidth = imageHeight * 232 / 250 //todo: agafar aquests valors dinàmicament

            return (
                <View style={{flex: 1}}>
                    <TouchableOpacity style={{flex: 1}} onPress={() => { this.changeScreen(); }}>
                        <ImageBackground
                            source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs == '01_background_inici')['imatge_recurs' + this.state.idioma].url}}
                            style={{flex: 1, resizeMode: "cover", justifyContent: "center", }}>

                                {
                                    //this.mostrarPaginaPrincipal()
                                }
                        </ImageBackground>
                    </TouchableOpacity>
                    {/* <View style={{ bottom: "0", position:'absolute', alignContent: "center"}}>
                        <TouchableOpacity style={{flex: 1, alignContent: "flex-end", marginLeft: "auto", textAlign: "center"}}
                                          onPress={() => { this._handlePressButtonAsync("https://www.ccgarraf.cat") }}>
                                <Image
                                    resizeMode="cover"
                                    style={{width: imageWidth, height: imageHeight}}
                                    source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs == 'buto_info_pagina_principal')['imatge_recurs' + this.state.idioma].url}}
                                />
                        </TouchableOpacity>
                    </View> */}
                </View>
            )
        } else {
            return (
                <View style={{flex: 1, justifyContent: 'center'}}>
                    <TouchableOpacity>
                        <Image style={{alignSelf: 'center', width: 150, height: 150, marginTop: 10}}
                               source={require('./img/loading.gif')}/>
                    </TouchableOpacity>
                </View>
            )
        }
    }
}

///////////////////////* NAVIGATION PART *///////////////////////////////

const Tab = createBottomTabNavigator();
const Stack = createStackNavigator();

const App = () => (
    <NavigationContainer>
        <Stack.Navigator screenOptions={{
            headerShown: false,
        }}>
            <Stack.Screen name="Main" Proves component={Main}/>
            <Stack.Screen name="VideoPrincipal" component={VideoPrincipal}/>
            <Stack.Screen name="Login" component={Login}/>
            <Stack.Screen name="Inici" component={Inici}/>
            <Stack.Screen name="Rondalla" component={Rondalla}/>
            <Stack.Screen name="Pagina6" component={Pagina6}/>
            <Stack.Screen name="Home" component={Home2}/>
            <Stack.Screen name="Itineraris" component={Itineraris}/>
            <Stack.Screen name="InfoItinerari" component={InfoItinerari}/>
            {/* <Stack.Screen name="Home" component={Home} /> /*Per si es vol el menu normal (com el den met) */}
            <Stack.Screen name="Joc1" component={Joc1}/>
            <Stack.Screen name="Joc2" component={Joc2}/>
            <Stack.Screen name="Joc3" component={Joc3}/>
            <Stack.Screen name="Mapa" component={Mapa}/>
            <Stack.Screen name="JocTest" component={JocTest}/>
            {/* JOCS MAPA */}
            <Stack.Screen name="Quizz" component={Quizz}/>
            <Stack.Screen name="DragAndDrop" component={DragAndDrop}/>
            <Stack.Screen name="QuizzText" component={QuizzText}/>
            <Stack.Screen name="CheckIn" component={CheckIn}/>
            {/* FI JOCS MAPA */}
            <Stack.Screen name="Premi" component={Premi}/>
            <Stack.Screen name="Video" component={VideoPantallaComplerta}/>
            <Stack.Screen name="Joc3Completa" component={Joc3Completa}/>
            <Stack.Screen name="Perfil" component={Perfil}/>
            <Stack.Screen name="Mineral" component={Mineral}/>
            <Stack.Screen name="Credits" component={Credits}/>
            <Stack.Screen name="Telefon" component={Telefon}/>
        </Stack.Navigator>
    </NavigationContainer>
)

export default App;